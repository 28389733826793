.text-primary {
   color: #8cba38 !important;
}

.bg-primary {
   background-color: #8cba38 !important;
}

.ant-menu-item-selected {
   background-color: #7ebc00 !important;
   color: #fff !important;
   height: 50px !important;
   font-size: 16px;
}

.ant-menu-root > li {
   border-radius: 0px !important;
   display: flex !important;
}

.ant-layout-sider-trigger {
   background-color: #8cba38 !important;
}

.ant-menu-item {
   color: #fff !important;
   font-size: 16px;
   height: 50px !important;
}
.ant-layout-sider-children {
   display: flex !important;
   flex-direction: column;
   padding: 20px 0px;
}

.bg-secondary {
   background-color: #172f3b !important;
}
