@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
   margin: 0;
   height: 100%;
   width: 100%;
}

svg {
   vertical-align: baseline;
   display: inline;
}
